import React, { useState, useEffect } from 'react';
import Intro from 'components/views/Integrations/Intro';
import ActiveIntegrations from 'components/views/Integrations/ActiveIntegrations';
import {
  getAvailableIntegrationsSources,
  getActiveIntegrationsSources,
  updateOAuthIntegration,
} from 'api/integrationsAPI';
import UpdateIntegrationModal from 'components/views/Integrations/UpdateIntegrationModal';
import useAsync from 'hooks/useAsync';
import IntegrationsCategoryBlock from 'components/views/Integrations/IntegrationsCategoryBlock';
import { INTEGRATION_CATEGORIES, INTEGRATION_CATEGORY_NAMES } from 'components/views/Integrations/constants/Constants';
import DeleteIntegrationModal from 'components/views/Integrations/DeleteIntegrationModal';
import useRouter from 'hooks/useRouter';
import { oauthStateLSManager } from 'helper/localStorage';
import { useSelector } from 'react-redux';
import { getCurrentLicense, getIsLicenseAdmin } from 'store/license/getters';
import { MESSAGES } from 'constants/messages';
import { Grid } from '@mui/material';
import { GUIDES } from 'constants/guides';

const Integrations = () => {
  const [availableIntegrationsSources, setAvailableIntegrationsSources] = useState([]);
  const [activeIntegrationsSources, setActiveIntegrationsSources] = useState(undefined);
  const [selectedIntegration, setSelectedIntegration] = useState(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [modalError, setModalError] = useState({});
  const { query, pathname, replace } = useRouter();

  const { is_demo } = useSelector(getCurrentLicense);
  const isAdmin = useSelector(getIsLicenseAdmin);
  const readonly = !isAdmin || (is_demo && !isAdmin);

  const sortIntegrationsByCategory = (list, categoryName, sourceField = '', notEqual = false) => list.filter((item) => {
    const source = sourceField ? item[sourceField] : item;
    return notEqual ? source.category !== categoryName : source.category === categoryName;
  });
  const onClickDelete = () => {
    setOpenDeleteModal(true);
  };

  const completeOAuthFlow = async (newSelectedIntegration, urlParams) => {
    const oAuthState = oauthStateLSManager.get();

    // CSRF protection check
    if (urlParams.state === oAuthState.stateId && oAuthState.credentials) {
      try {
        await updateOAuthIntegration({
          integrationUUID: newSelectedIntegration.uuid,
          data: {
            oauth_code: urlParams.code,
            credentials: oAuthState.credentials,
          },
        });
        newSelectedIntegration.credential_fields_names = [];
        setModalStep(2);
      } catch (error) {
        setModalStep(1);
        setModalError(error);
      }

      setSelectedIntegration(newSelectedIntegration);
    }

    oauthStateLSManager.remove();
    replace({
      search: '',
      pathname: pathname.replace(`/${urlParams.sourceKey}`, ''),
    });
  };

  const loadAvailableIntegrationsSources = async (urlParams) => {
    const availableSources = await getAvailableIntegrationsSources();
    if (availableSources) {
      setAvailableIntegrationsSources(availableSources);

      const newSelectedIntegration = availableSources.find((x) => x.key === urlParams.sourceKey);
      if (newSelectedIntegration) {
        await completeOAuthFlow(newSelectedIntegration, urlParams);
      }
    }
  };
  const loadActiveIntegrationsSources = async () => {
    const activeSources = await getActiveIntegrationsSources();
    if (activeSources) {
      let primaryIntegrations = sortIntegrationsByCategory(activeSources, INTEGRATION_CATEGORIES.E_COMMERCE, 'source');
      const secondaryIntegrations = sortIntegrationsByCategory(activeSources, INTEGRATION_CATEGORIES.E_COMMERCE, 'source', true);
      if (secondaryIntegrations.length) {
        primaryIntegrations = primaryIntegrations.map((item) => ({
          ...item,
          source: { ...item.source, disableDelete: true },
        }));
      }
      setActiveIntegrationsSources({
        primaryIntegrations,
        secondaryIntegrations,
      });
    }
  };
  const {
    execute: loadAvailableIntegrationsSourcesAsync,
  } = useAsync(loadAvailableIntegrationsSources);
  const {
    execute: loadActiveIntegrationsSourcesAsync,
  } = useAsync(loadActiveIntegrationsSources);
  const updateIntegration = (integration) => {
    setSelectedIntegration(integration);
  };
  const updateListsOfIntegrations = (urlParams) => {
    loadActiveIntegrationsSourcesAsync();
    loadAvailableIntegrationsSourcesAsync(urlParams);
  };
  const onConfirmDeleteIntegration = () => {
    setOpenDeleteModal(false);
    setSelectedIntegration(undefined);
    updateListsOfIntegrations();
  };
  const onConfirmUpdateIntegration = () => {
    updateListsOfIntegrations();
    setSelectedIntegration(undefined);
    setModalStep(1);
    setModalError({});
  };
  const onCancelUpdateIntegration = () => {
    setOpenDeleteModal(undefined);
    setSelectedIntegration(undefined);
    setModalStep(1);
    setModalError({});
  };

  const parseUrlParams = () => {
    let params = {};
    if (query.code && query.state) {
      const sourceKey = pathname.split('/').pop();
      params = {
        ...query,
        sourceKey,
      };
    }

    return params;
  };

  useEffect(() => {
    const urlParams = parseUrlParams();
    updateListsOfIntegrations(urlParams);
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancelUpdateIntegration();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <Grid container>
      <Grid item container spacing={2}>
        <Grid item lg={4} xs={12}>
          {!!activeIntegrationsSources
          && !activeIntegrationsSources.primaryIntegrations.length && (
            <Intro />
          )}
          {!!activeIntegrationsSources
          && !!activeIntegrationsSources.primaryIntegrations.length && (
            <ActiveIntegrations
              integrations={activeIntegrationsSources}
              updateActiveIntegrationsList={loadActiveIntegrationsSourcesAsync}
              updateAvailableIntegrationsList={loadAvailableIntegrationsSourcesAsync}
              editIntegration={updateIntegration}
              readonly={readonly}
            />
          )}
        </Grid>
        <Grid item lg={8} xs={12}>
          {!!availableIntegrationsSources.length && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IntegrationsCategoryBlock
                  header={MESSAGES.primary}
                  itemSize={3}
                  postHeader={INTEGRATION_CATEGORY_NAMES.E_COMMERCE}
                  guide={GUIDES.INT1}
                  isPrimary
                  activePrimaryId={!!activeIntegrationsSources
                    && !!activeIntegrationsSources.primaryIntegrations[0]
                    && activeIntegrationsSources.primaryIntegrations[0].source.uuid}
                  updateIntegration={updateIntegration}
                  list={sortIntegrationsByCategory(
                    availableIntegrationsSources,
                    INTEGRATION_CATEGORIES.E_COMMERCE,
                  )}
                  readonly={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <IntegrationsCategoryBlock
                  itemSize={3}
                  postHeader={INTEGRATION_CATEGORY_NAMES.EMAIL_MARKETING}
                  guide={GUIDES.INT2}
                  updateIntegration={updateIntegration}
                  list={sortIntegrationsByCategory(
                    availableIntegrationsSources,
                    INTEGRATION_CATEGORIES.EMAIL_MARKETING,
                  )}
                  readonly={readonly}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <IntegrationsCategoryBlock
                  itemSize={6}
                  postHeader={INTEGRATION_CATEGORY_NAMES.ANALYTICS}
                  guide={GUIDES.INT5}
                  updateIntegration={updateIntegration}
                  list={sortIntegrationsByCategory(
                    availableIntegrationsSources,
                    INTEGRATION_CATEGORIES.ANALYTICS,
                  )}
                  readonly={readonly}
                />
              </Grid> */}
              <Grid item xs={12}>
                <IntegrationsCategoryBlock
                  itemSize={3}
                  postHeader={INTEGRATION_CATEGORY_NAMES.AD_PROVIDERS}
                  guide={GUIDES.INT6}
                  updateIntegration={updateIntegration}
                  list={sortIntegrationsByCategory(
                    availableIntegrationsSources,
                    INTEGRATION_CATEGORIES.AD_PROVIDERS,
                  )}
                  readonly={readonly}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <IntegrationsCategoryBlock
                  itemSize={4}
                  postHeader={INTEGRATION_CATEGORY_NAMES.CUSTOMER_SERVICE}
                  guide={GUIDES.INT4}
                  updateIntegration={updateIntegration}
                  list={sortIntegrationsByCategory(
                    availableIntegrationsSources,
                    INTEGRATION_CATEGORIES.CUSTOMER_SERVICE,
                  )}
                  readonly={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <IntegrationsCategoryBlock
                  itemSize={4}
                  postHeader={INTEGRATION_CATEGORY_NAMES.SOCIAL_NETWORKS}
                  guide={GUIDES.INT3}
                  updateIntegration={updateIntegration}
                  list={sortIntegrationsByCategory(
                    availableIntegrationsSources,
                    INTEGRATION_CATEGORIES.SOCIAL_NETWORKS,
                  )}
                  readonly={readonly}
                />
              </Grid> */}
            </Grid>
          )}
        </Grid>
      </Grid>
      {!!selectedIntegration && (
      <UpdateIntegrationModal
        open={!!selectedIntegration && !openDeleteModal}
        integration={selectedIntegration}
        modalStep={modalStep}
        modalError={modalError}
        onDelete={onClickDelete}
        onCancel={onCancelUpdateIntegration}
        editMode={!!selectedIntegration?.source}
        onConfirm={onConfirmUpdateIntegration}
      />
      )}
      <DeleteIntegrationModal
        open={!!selectedIntegration && openDeleteModal}
        onCancel={onCancelUpdateIntegration}
        onConfirm={onConfirmDeleteIntegration}
        integration={selectedIntegration?.source
          ? selectedIntegration?.source
          : selectedIntegration}
      />
    </Grid>
  );
};
export default Integrations;
