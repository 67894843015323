import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as HomeIcon } from 'assets/svg/home.svg';
import { ReactComponent as CustomerIcon } from 'assets/svg/heart.svg';
import { ReactComponent as ProductsIcon } from 'assets/svg/tag.svg';
import { ReactComponent as SegmentsIcon } from 'assets/svg/segments.svg';
import { ReactComponent as ActivationIcon } from 'assets/svg/activation.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/svg/dataBase.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ReactComponent as CohortsIcon } from 'assets/svg/graphs.svg';
import { ReactComponent as ExperimentsIcon } from 'assets/svg/experiments.svg';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { getCurrentLicense, getHasDataAccess, getIsLicenseAdmin } from 'store/license/getters';
import { getSegments } from 'store/segments/getters';
import { MESSAGES } from 'constants/messages';
import { SEGMENT_NAME } from 'constants/segmentNames';
import CombinedSideBarMenu from 'containers/PrivateApp/Portal/CombinedSideBarMenu';

const prepareSegmentsConfig = (segments) => segments
  .filter((item) => item.segment.segment_name !== SEGMENT_NAME.departed_customers)
  .map(({ id, segment }) => ({
    id,
    title: segment.segment_name,
    hrefTo: `/segments/${id}`,
    disabled: id === null,
  }));

const productsCategory = [{
  id: 'products',
  title: MESSAGES.products,
  hrefTo: ROUTES.products,
  disabled: false,
},
{
  id: 'productCategories',
  title: MESSAGES.categories,
  hrefTo: ROUTES.productCategories,
  disabled: false,
}];

const PortalMenu = ({
  defaultSideBar, viewType, toggleDrawer, toggleMenu, isMenuOpened, onCloseSlideMenu,
}) => {
  const { is_demo } = useSelector(getCurrentLicense);
  const segments = useSelector(getSegments);

  const isAdmin = useSelector(getIsLicenseAdmin);
  const hasDataAccess = useSelector(getHasDataAccess);

  const mainItems = [{
    hrefTo: ROUTES.home,
    IconComponent: HomeIcon,
    name: MESSAGES.home,
  }];
  if (hasDataAccess) {
    mainItems.push(...[{
      hrefTo: ROUTES.customers,
      IconComponent: CustomerIcon,
      name: MESSAGES.customers,
    }, {
      hrefTo: ROUTES.products,
      IconComponent: ProductsIcon,
      name: MESSAGES.products,
      config: productsCategory,
    }, {
      hrefTo: ROUTES.segments,
      IconComponent: SegmentsIcon,
      name: MESSAGES.segments,
      config: prepareSegmentsConfig(segments),
    }, {
      hrefTo: ROUTES.cohorts,
      IconComponent: CohortsIcon,
      name: MESSAGES.cohorts,
    }, {
      hrefTo: ROUTES.experiments,
      IconComponent: ExperimentsIcon,
      name: MESSAGES.experiment,
    }, {
      hrefTo: ROUTES.customAudiences,
      IconComponent: ActivationIcon,
      name: MESSAGES.activation,
    }]);
  }

  const additionalItems = [{
    hrefTo: ROUTES.licenseSettings,
    IconComponent: SettingsIcon,
    name: MESSAGES.settings,
  }];
  (isAdmin || is_demo) && additionalItems.unshift({
    hrefTo: ROUTES.integrations,
    IconComponent: IntegrationsIcon,
    name: MESSAGES.integrations,
  });

  return (
    <CombinedSideBarMenu
      mainItems={mainItems}
      additionalItems={additionalItems}
      isMenuOpened={isMenuOpened}
      defaultSideBar={defaultSideBar}
      viewType={viewType}
      toggleDrawer={toggleDrawer}
      toggleMenu={toggleMenu}
      onCloseSlideMenu={onCloseSlideMenu}
    />
  );
};

export default PortalMenu;

PortalMenu.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
  defaultSideBar: PropTypes.string,
  viewType: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  onCloseSlideMenu: PropTypes.func,
};

PortalMenu.defaultProps = {
  defaultSideBar: undefined,
  onCloseSlideMenu: null,
};
